<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
</style>

<script>

//import axios from 'axios'
import { AUTH_SUCCESS, AUTH_SIGNOUT } from './store/actions/auth'
import duppla from '@/duppla'

export default {
  name: 'app',
  metaInfo: {
    title: '',
    titleTemplate: '%s' + process.env.VUE_APP_TITLE
  },
  beforeCreate: function () {
    window.addEventListener('storage', this.syncLogout)
    //console.log('route name: ' + JSON.stringify(this.$router.currentRoute, null,2))
    let accessToken = this.$store.state.auth.accessToken
    if (accessToken) {
      this.$store.dispatch(AUTH_SUCCESS, accessToken).then(() => {
        //this.$router.push({ name: 'dashboard'})
        
        //Obligamos a redirigir a la misma liga de origen, pero esta ves router.index.js considerará condiciones relacionadas a la autenticación
        this.$router.push(window.location.pathname)
      })
    }

  },
  mounted: function () {
    //console.log(this.$router)
    //console.log(this.$router.currentRoute)
  }
}
</script>