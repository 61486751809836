import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'signup',
    component: () => import('../views/public/signup'),
    meta: {
      requiresNoAuth: true
    }
  },
  {
    path: '/signup/ok',
    name: 'signup-success',
    component: () => import('../views/public/signup-ok'),
    meta: {
      requiresNoAuth: true
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/public/auth'),
    meta: {
      requiresNoAuth: true
    }
  },
  {
    path: '/terminos',
    name: 'terms-of-service',
    component: () => import('../views/public/terms-of-service')
  },
  {
    path: '/privacidad',
    name: 'privacy',
    component: () => import('../views/public/privacy')
  },
  {
    path: '/404',
    name: 'direct 404',
    component: () => import('../views/public/404')
  },
  {
    path: '/signout',
    name: 'signout',
    component: () => import('../views/public/signout'),
    meta: {
      requiresNoAuth: true
    }
  },
  {
    path: '/private/medical-record-wizard',
    name: 'medical-record-wizard',
    component: () => import('../views/private/medical-record-wizard'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/private/dashboard',
    name: 'dashboard',
    component: () => import('../views/private/dashboard'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/private/medical-record',
    name: 'medical-record',
    component: () => import('../views/private/medical-record'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/private/payment',
    name: 'payment',
    component: () => import('../views/private/payment'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'indirect 404',
    component: () => import('../views/public/404')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {

  let accessToken = store.state.auth.accessToken
  let isAuthenticated = store.state.auth.isAuthenticated

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to signout page.
    if (!isAuthenticated && !accessToken) {
      next({ name: 'signout' }) //suppress errors: https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
    } else {
      next() // go to wherever I'm going
    }
  } else if (to.matched.some(record => record.meta.requiresNoAuth)) {
    if (isAuthenticated && accessToken) {
      next({ name: 'dashboard' }) //suppress errors: https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() 
  }


/*
  if (to.path.indexOf('/private') >= 0) {
    if (!isAuthenticated && !accessToken) {
      next('/signout')
      return
    }
  }

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
*/
})

export default router
